<template>
  <div class="container">
    <div class="content">
      <div class="login_top">
        <div class='login_top_left'>{{$t('login.newUser')}}</div>
      </div>
      <div class="login_filed">
        <van-cell-group>
          <van-field v-model="loginForm.phone" type="test"  clearable :placeholder="$t('login.phone')" />
          <van-field v-model="loginForm.firstpwd" :type="firstType" minlength='8' clearable maxlength='16'
             :placeholder="$t('login.pwsTips')">
            <my-icon fontSize="32" slot='right-icon' :iconName="iconfirst" @click="showPwd('first')"></my-icon>
          </van-field>
          <van-field v-model="loginForm.secondPwd" :type="secondType" clearable  :placeholder="$t('login.inputSurePws')">
            <my-icon fontSize="32" slot='right-icon' :iconName="iconsecond" minlength='8' maxlength='16'
              @click="showPwd('second')"></my-icon>
          </van-field>
          <van-field v-model="loginForm.userName" clearable type="test" maxlength='40' :placeholder="$t('login.userName')" />
          <van-field v-model="loginForm.verifyCode" @input="codeOnlyNumber" minlength='4' maxlength='6'
          :placeholder="$t('login.inputCode')" use-button-slot>
          <van-button slot="button" :class="codeDisabled ? 'disabled_style' : 'btn_style'" type="primary"
            @click="sendcodeHandle" :disabled="disabledCode">
            {{btnText}}
          </van-button>
          </van-field>
        </van-cell-group>
      </div>
      <van-button class="login_bn" :disabled="isDisableLoginBtn()" type="primary" @click="handleRegister">
        {{$t('login.onLogin')}}</van-button>
      <Back :type='goType'></Back>
      <div class='register_btm'>
        <van-checkbox v-model='loginForm.checked' shape="square">
          {{$t('login.okFine')}}
        </van-checkbox>
        <div class='service_style' @click='goservices'>{{$t('login.termService')}}</div>
      </div>
    </div>
  </div>
</template>
<script>
  import Back from "@/views/login/back.vue";
  // 密码加密组件
  import sha256 from "crypto-js/sha256";
  import Base64 from "crypto-js/enc-base64";
  import CryptoJS from "crypto-js";
  import {
    permituserRegist,
    sendCode
  } from "@/api/meet"

  import {
    checkCode,
    checkMobile,
    checkchangePwd,
    checkpwd
  } from "@/utils/check";
  export default {
    components: {
      Back,
    },
    data() {
      return {
        goType:'register',
        loginForm: {
          phone: '',
          firstpwd: '',
          secondPwd: '',
          userName: '',
          checked: false,
          verifyCode: '',
        },
        type:'register',
        firstType: 'password',
        secondType: 'password',
        iconfirst: 'icon-mima_bukedu_48_hei',
        iconsecond: 'icon-mima_bukedu_48_hei',
        btnText: this.$t('login.getCode'),
        timer: null,
        count: 60,
        codeDisabled:false,
        noSend:true,
      }
    },
    computed:{
      disabledCode(){
        return !checkMobile(this.loginForm.phone)
      },
    },
    methods: {
      codeOnlyNumber(value) {
        this.loginForm.verifyCode = value.replace(/[^\d]/g, "");
      },
        // 验证码倒计时
        getCode() {
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.codeDisabled = true;
          this.noSend = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count = this.count - 1;
              this.btnText = this.count + this.$t('login.resend');
            } else {
              this.codeDisabled = false;
              this.noSend = true;
              clearInterval(this.timer);
              this.btnText = this.$t('login.getCode');
              this.timer = null;
            }
          }, 1000);
        }
      },
       sendcodeHandle() {
        if (!checkMobile(this.loginForm.phone)) {
          this.$toast(this.$t('login.try'))
          return
        }
        if(!this.noSend) return
        sdk.auth.getVerifyCode(this.loginForm.phone)
      .then(res=> {
        this.$toast('发送成功');
        this.getCode();
      })
      .catch(error=>{
        this.$eventBus.$emit('network-error', error)
      })

      },
      // 清除倒计时
      destroyed() {
        if (this.timer) {
          clearInterval(this.timer);
        }
      },
      goservices(){
          this.$router.push({
            path: 'agreement',
          })
      },
      showPwd(type) {
        let bukedu = 'icon-mima_bukedu_48_hei',
          kedu = 'icon-mima_kedu_48_hei';
        if (type == 'first') {
          this.firstType = this.firstType == 'test' ? 'password' : 'test'
          this.iconfirst = this.iconfirst == bukedu ? kedu : bukedu
        } else {
          this.secondType = this.secondType == 'test' ? 'password' : 'test'
          this.iconsecond = this.iconsecond == bukedu ? kedu : bukedu
        }
      },
      isDisableLoginBtn() {
        return !(this.loginForm.phone && this.loginForm.firstpwd && this.loginForm.secondPwd && this.loginForm
          .userName && this.loginForm.verifyCode && this.loginForm.checked)
      },
      // 点击注册
      async handleRegister() {
        let that = this;
        if (!checkpwd(this.loginForm.firstpwd)|| !checkpwd(this.loginForm.secondPwd)) {
            this.$toast('密码由8-16位大小写字母、数字以及字符组成')
            return
          }
        if (!checkCode(this.loginForm.verifyCode)) {
              this.$toast(this.$t('login.failTextCode'))
              return
            }
        if (that.loginForm.firstpwd.length < 8 && that.loginForm.secondPwd.length < 8) {
          this.$toast(that.$t('reg.lengthPwd'))
          return
        }
        if (that.loginForm.firstpwd !== that.loginForm.secondPwd) {
          this.$toast(that.$t('reg.somePwd'))
          return
        }
        let data = {
          username: this.loginForm.phone,
          password: Base64.stringify(
            CryptoJS.enc.Utf8.parse(sha256(this.loginForm.firstpwd).toString())
          ),
          checkCode: this.loginForm.verifyCode,
          nickname: this.loginForm.userName,
        };

        try {
          await permituserRegist(data)
          this.$router.push({
            path: 'finishRegister'
          })
        } catch (error) {
            this.$eventBus.$emit('register-error', error)
        }
      }
    },
  }

</script>
<style lang="less" scoped>
  /* 登录部分输入框样式 */
  /deep/ .van-cell {
    padding: 10px 2px;
    margin-top: 18px;
    border-bottom: 0.1px solid #E5E5E5;

    &::after {
      right: 2px;
      left: 2px;
      border: none;
    }

    &:hover {
      border-bottom: 0.1px solid #1AB370;
    }
  }

  /deep/ .van-hairline--top-bottom::after {
    border: none;
  }

  .container {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    .content {
      padding: 80px 64px;
      box-sizing: border-box;

      .login_top {
        display: flex;
        justify-content: space-between;
        line-height: 64px;

        &_left {
          font-size: 48px;
          color: #333333;
        }
      }

      .login_filed {
        margin-top: 60px;
          .public-code() {
            width: 198px;
            height: 64px;
            border-radius: 4px;
            font-size: 24px;
          }

          /deep/ .van-button--normal {
            padding: 0px;
          }

          .btn_style {
            background: rgba(255, 255, 255);
            border: 1px solid #FF8200;
            color: #FF8200;
            .public-code();
          }

          .disabled_style {
            border: none;
            background: #FFDFA3;
            color: #FFFFFF;
            .public-code();
          }
      }

      .login_bn {
        width: 622px;
        height: 88px;
        background: #1AB370;
        border-radius: 10px;
        border: none;
        margin: 80px 0 40px 0;
        font-size: 32px;
      }

      .register_btm {
        display: flex;
        font-size: 28px;
        color: #666666;

        /deep/ .van-checkbox__label {
          color: #666666;
          font-family: PingFangSC-Regular, PingFang SC;
        }

        /deep/ .van-checkbox__icon--checked .van-icon {
          color: #fff;
          background-color: #1AB370;
          border-color: #1AB370;
        }

        .service_style{
          color: #1AB370;
        }
      }
    }
  }

</style>
